import { ITheraLocale, ITheraNavigation } from './ithera-locale';

export const navExtrasEs: ITheraNavigation = {
  EXTRA_NAV: {
    THREATMENT: 'Tratamiento',
    ABOUT: 'Acerca de QFNA',
    SIGN_OUT: 'Cerrar sesión',
    EDIT_PROFILE: 'Editar perfil',
    TRAINING_OVERVIEW: 'Descripción General del Tratamiento',
  },
};

export const locale: ITheraLocale = {
  lang: 'es',
  code: 'ES',
  humanTitle: 'Español',
  data: {
    COMMON: {
      EMAIL: 'Correo Electrónico',
      YOUR_EMAIL: 'Tu dirección de correo electrónico',
      EMAIL_INVALID: 'Corre electronico no es valido',
      EMAIL_REQUIRED: 'Correo electronico es obligatorio',
      PHONE: 'Teléfono',
      YOUR_PHONE: 'Teléfono',
      PHONE_INVALID: 'Teléfono no válido',
      PHONE_REQUIRED: 'Teléfono obligatorio',
      UNIQUE_ID: 'Identificador único.',
      UNIQUE_ID_INVALID: 'Id Único no válido',
      UNIQUE_ID_REQUIRED: 'El identificador único es obligatorio',
      PASSWORD: 'Contraseña',
      YOUR_PASSWORD: 'Su Contraseña',
      PASSWORD_REQUIRED: 'Contraseña obligatoria',
      PASSWORD_MUST_MATCH: 'Las contraseñas deben coincidir',
      HERE: 'aquí',
      YOUR_LANGUAGE: 'Su Idioma',
      PERSONAL_INFO: {
        YOUR_PERSONAL_INFO: 'Sus datos personales',
        YOUR_DATE_OF_BIRTH: 'Tu fecha de nacimiento',
        DATE_OF_BIRTH_REQUIRED: 'La fecha de nacimiento es obligatoria',
        DATE_OF_BIRTH_INVALID: 'Ingrese una Fecha De Nacimiento válida',
        DATE_OF_BIRTH_MINIMAL: 'La edad mínima es de 18 años',
        YOUR_FIRST_NAME: 'Tu nombre',
        FIRST_NAME_REQUIRED: 'El nombre es obligatorio',
        FIRST_NAME_INVALID: 'Por favor, introduzca un Nombre válido',
        YOUR_LAST_NAME: 'Tu Apellido',
        LAST_NAME_REQUIRED: 'Apellido obligatorio',
        LAST_NAME_INVALID: 'Por favor, introduzca un Apellido válido',
        ZIP_CODE: 'Código Postal',
        ZIP_CODE_REQUIRED: 'Se requiere código postal',
        NAME: 'Nombre',
        NAME_REQUIRED: 'Nombre obligatorio',
        NAME_INVALID: 'Por favor, introduzca un nombre válido',
      },
      TIME: {
        WEEK: 'Semana',
        DAY: 'Día',
        DATE: 'Fecha',
        TODAY: 'Hoy en día',
        TOMORROW: 'Mañana',
        MON_TEXT: 'Lunes',
        TUE_TEXT: 'Martes',
        WEN_TEXT: 'Miércoles',
        THU_TEXT: 'Jueves',
        FRI_TEXT: 'Viernes',
        SAT_TEXT: 'Sábado',
        SAN_TEXT: 'Domingo',
      },
      ACTIONS: {
        CONTINUE: 'Continuar',
        GO_BACK_A_STEP: 'Retroceder un paso',
        GO_BACK: 'Volver',
        BACK: 'Atrás',
        GO_BACK_TO_LOGIN: 'Volver al inicio de sesión',
        GO_TO_SIGNIN: 'Ir A Inicio De Sesión',
        SAVE: 'Guardar',
        CANCEL: 'Cancelar',
        ACKNOWLEDGE: 'Reconocer',
        SIGN_OUT: 'Cerrar sesión',
        REMOVE: 'Eliminar',
        START: 'Comenzar',
        STOP: 'Detener',
        SKIP: 'Saltar',
        EDIT: 'Editar',
        YES: 'Sí',
        NO: 'NO',
      },
      STATUSES: {
        SUCCSESS: '¡Éxito!',
        FAILURE: '¡Fracaso!',
      },
      DOMAIN_COMMON: {
        IN_STUDIO: 'En la clínica',
        OUT_OF_STUDIO: 'En casa',
        SCHEDULE: 'Horario',
        OVERVIEW: 'Visión general',
        CLASS: 'Clase',
        STATE: 'Estado',
        LOCATION: 'Ubicación',
        TRAINER: 'Entrenador',
        STATION: 'Estación',
      },
    },
    DIALOGS: {
      WARNING_TITLE: 'Advertencia',
      DELETE_ACC_TITLE1: 'La cuenta',
      DELETE_ACC_TITLE2: 'será eliminada',
      DELETE_ACC_DESC: '¿Quieres continuar?',
      ATTEMPT_START_NEW_LVL:
        'Espere. Está intentando iniciar un nuevo nivel de su programa sin haber completado el nivel anterior. Te recomendamos que vuelvas atrás y completes el día de entrenamiento: {replace1}  antes de completar el día de entrenamiento: (Semana {replace2} Día {replace3})',
      ATTEMPT_START_NO_REST:
        'Espera. Estás intentando completar otro día de entrenamiento sin el descanso y la recuperación adecuada. Recomendamos 24-48 horas entre días de entrenamiento para optimizar la curación y regeneración del sistema.',
      INSTRUCTOR_CHAT:
        'Si tiene alguna pregunta sobre su programa de ejercicios, puede comunicarse con {replace1} mediante un mensaje de texto. ¿Iniciar una conversación por SMS ahora?',
    },
    NAV: {
      ROOT: 'Root',
      DASHBOARD: 'Panel de mando',
      TRAINING: 'Tratamiento',
      TRAINING_SESSION: 'Sesión de Entrenamiento',
      TRAINING_OVERVIEW: 'Descripción General del Tratamiento',
      RESCHEDULE_IN_STUDIO_SESSION: 'Reprogramar En Sesión De Estudio',
      TRAINING_DETAILS: 'Detalles de Entrenamiento',
      GREAT_WORKOUT: 'Gran Entrenamiento',
      RESCHEDULE_SESSION: 'Reprogramar Sesión',
      RESCHEDULE_IN_STUDIO_TRAINING: 'Reprogramar En Entrenamiento De Estudio',
      SCHEDULE_IN_STUDIO_TRAINING: 'Programe Capacitación En el Estudio',
      SCHEDULE_ASSESSMENT: 'Evaluación del Cronograma',
      TESTING: 'Pruebas',
      TESTING_ASSESSMENT_HISTORY: 'Historial de Evaluación de Pruebas',
      TESTING_HISTORY: 'Historial de Pruebas',
      TEST_SUMMARY: 'Resumen de la Prueba',
      NOT_AUTHORIZED: 'No Autorizado',
      PREFERENCES: 'Preferencias',
      EDIT_PROFILE: 'Editar perfil',
      CHANGE_PASSWORD: 'Cambiar contraseña',
      SWITCH_PROFILE: 'Cambiar perfil',
      SIGNED_IN_AS: 'Iniciar sesión como',
      TESTING_OVERVIEW: 'Descripción General de las Pruebas',
      SLEEP_TRACKER: 'Rastreador de Sueño',
      HYDRATION_TRACKER: 'Rastreador de hidratación',
    },

    EXTRA_NAV: navExtrasEs.EXTRA_NAV,
    LOGIN: {
      SIGN_IN: 'Iniciar sesión',
      SIGNING_IN: 'Iniciar Sesión...',
      PASSWORD: 'Contraseña',
      PASSWORD_REQUIRED: 'Contraseña obligatoria',
      NEW_USER: '¿Nuevo usuario?',
      REGISTER: 'Regístrese en',
      FORGOT_PASSWORD: '¿Ha olvidado su contraseña?',
      RETURN_URL_ERROR:
        'Url de retorno no válida. La URL de retorno debe tener el mismo origen que la página actual.',
    },
    REGISTER: {
      CREATE_AN_ACCOUNT: 'Crear una cuenta',
      ENTER_YOUR_CONACT_INFO:
        'Introduzca su información de contacto, que se utilizará para iniciar sesión.',
      FOR_ADULTS: 'Si eres mayor de edad, utiliza tus propios datos.',
      AGE_WARNING:
        'Debes tener 18 años o más para crear una cuenta. Si eres menor de 18, pídele a un adulto que se registre y luego cree un perfil para ti.',
      ENTER_UNIQUE_ID:
        'Introduzca el identificador único y la fecha de nacimiento para identificar si cumple los requisitos para registrarse',
      UNABLE_TO_VERIFY_EMAIL_UNIQ_ID:
        'No hemos podido verificar su dirección de correo electrónico. Por favor, proporcione su identificador único de correo electrónico de invitación de registro y la fecha de nacimiento para crear una cuenta',
      CREATE_WITH_UNIQ_ID: 'Crear con ID único',
      RETURN_TO_SIGN_IN: 'Volver al inicio de sesión',
      CREATE_PROFILE: 'Crear perfil',
      DATE: 'Fecha',
      DATE_REQUIRED: 'Se requiere fecha',
      DATE_INVALID: 'Por favor, introduzca una fecha válida',
      WAIVER_OF_LIABILITY: 'Exención de responsabilidad',
      I_AGREE: 'Acepto',
    },
    REGISTER_COMPLETE: {
      REGISTRATION_FINISHED: '¡Registro finalizado!',
      YOU_REGISTERED:
        'Ahora que te has registrado, tienes que crear un perfil para cada persona que utilice esta aplicación.',
    },
    IN_TAKE: {
      PROFILE_CREATED: '¡Perfil creado!',
      YOU_CREATED_PROFILE: 'Ha creado correctamente un perfil.',
      GOTO_DASHBOARD: 'Ir al panel de control',
      ADD_ANOTHER_PROFILE: 'Añadir otro perfil',
    },
    PERSONAL_INFO: {
      ENTER_YOUR_PERSONAL_INFO:
        'Introduce tu información personal a continuación.',
      IF_YOU_CREATING_FOR_YOUTH:
        'Si estás configurando esta aplicación para un menor de 18, crearás un perfil para él más adelante en el proceso.',
      DATE_OF_BIRTH_TO_SMALL_NOFITICATION:
        'Debe tener 18 años o más para crear una cuenta. Si eres joven, pídele a un adulto que se registre y luego cree un perfil para ti.',
    },
    FORGOT_PASSWORD: {
      TO_RESET_PASSWORD:
        'Para restablecer tu contraseña, introduce tu correo electrónico o teléfono y te enviaremos un enlace para cambiar tu contraseña.',
      EMAIL_OR_PHONE: 'Correo electrónico o teléfono',
      ENTER_VALID_EMAIL:
        'Por favor, introduzca una dirección de correo electrónico válida',
      REQUEST_NEW_PASSWORD: 'Solicitar nueva contraseña',
      CHECK_YOUR_EMAIL_ADDRESS: 'Revisa tu correo electronico',
      CONFIRMATION_SENT_TO:
        'Se ha enviado un correo electrónico de confirmación a',
      CHECK_INBOX:
        'Revise su bandeja de entrada y haga clic en el enlace "Cambiar contraseña" para restablecer su contraseña.',
    },
    RESET_PASSWORD: {
      PLEASE_CHANGE_YOUR_PASSWORD:
        'E - Please change your password to a secure and memorable password.',
      PASSWORD_MUST_INCLUDE:
        'E - It must include 8 characters, 1 special character, and 1 number.',
      UPDATE_PASSWORD: 'E - Update Password',
      YOU_UPDATED_PASSWORD:
        'E - You’ve updated your password and can now sign in.',
      RESET_PASSWORD_REQUEST_INCORRECT:
        "E - Reset password request URL you are trying to use has already been used and can't be used again.", // eslint-disable-line @typescript-eslint/quotes
      CONFIRM_PASSWORD: 'E - Confirm Password',
    },
    CHANGE_PASSWORD: {
      CHANGE_PASSWORD: 'Cambiar contraseña',
      CREATE_SECURE_PASSWORD:
        'Cree una contraseña segura y memorable para su cuenta.',
      PASSWORD_MUST_INCLUDE:
        'Debe incluir 8 caracteres, 1 carácter especial y 1 número.',
      PASSWORD_MUST_MATCH: 'Las contraseñas deben coincidir',
      CONFIRM_NEW_PASSWORD: 'Confirmar contraseña',
      CONFIRM_NEW_PASSWORD_REQUIRED: 'Se requiere «Confirmar contraseña»',
      NEW_PASSWORD: 'Nueva contraseña',
      NEW_PASSWORD_REQUIRED: 'Se requiere «Nueva contraseña»',
      PASSWORD_POLICY_ERROR: 'Error de política de contraseñas',
      PASSWORD_WAS_CHANGED: 'Se cambió la contraseña',
    },
    CHANGE_PROFILE: {
      CHANGE_PROFILE: 'Seleccionar perfil',
      WHICH_PROFILE: '¿Qué perfil desea utilizar?',
      CONTINUE_TO_DASHBOARD: 'Continuar con el panel de control',
      ADD_ANOTHER_PROFILE: 'Añadir otro perfil',
      DELETE_ACCOUNT: 'Eliminar cuenta',
    },
    CHECK_UNIQ_ID: {
      ADD_PROFILE: 'Añadir perfil',
      YOU_CAN_LINK_EXISTING:
        'Puede vincular un registro existente utilizando su ID único',
      OR_CREATE_NEW: 'O crear uno nuevo',
      LINK_EXISTING: 'Vincular existente',
    },
    DASHBOARD: {
      TREATMENT: 'Tratamiento',
      UPCOMING_EVENTS: 'Próximos eventos',
      UPCOMING_EVENTS_LIST: 'Aquí tiene una lista de sus próximos eventos.',
      NO_UPCOMING_EVENTS: 'No hay próximos eventos',
      YOU_HAVE_PROGRAMM: 'Tiene asignado un programa de ejercicios.',
      TO_SEE_EXERCISES_TAP: 'Para ver los ejercicios pulse',
      CHANGE_SCHEDULE: 'Cambiar Horario',
      TESTING: 'Pruebas',
      CONTACT: 'Contacto',
      BOOK_NOW: 'Reservar',
      CALL: 'Llame a',
      CHAT: 'Chatear',
      OPT_MOBILE_ONLY:
        'Esta opción solo está disponible en un dispositivo móvil.',
      PHONE_NOT_CONFIGURED:
        'El número de teléfono no está configurado para un proveedor determinado.',
    },
    ASSESSMENTS: {
      UPCOMING_TESTS: 'Próximos exámenes',
      NO_UPCOMING_TESTS: 'No hay próximas pruebas',
      DIAGNOSIS: 'Diagnóstico',
      EXERCISE_PROGRAM: 'Programa de Ejercicios',
      EXERCISE_PROGRAM_ASSIGNED:
        'Aquí está el programa de ejercicios que se le asignará después de completar la prueba.',
      NO_COMPLETED_TESTS:
        'Esta evaluación no contiene ninguna prueba completada.',
      NO_DIAGNOSIS: 'Aún no hay diagnóstico.',
      NO_EXERCISE_PROGRAM:
        'No hay ningún programa de ejercicios asignado para usted.',
      TAP_TO_VIEW_RESULTS:
        'Toque uno de los elementos a continuación para ver los resultados de cada categoría de prueba.',

      PDF_REPORT: 'Informe en PDF',
      RESULTS_NOT_AVAILIABLE:
        'Los resultados de su prueba estarán disponibles para descargar pronto.',
      TAP_TO_DOWNLOAD_REPORT:
        'Toque el icono para descargar los resultados de la prueba.',

      TEST_SUMMARY: 'Resumen de la prueba',
      VISION_TEST_TITLE: 'Visión',
      VISION_TEST_DESC:
        'The Sensory Motor Skills Assessment analyzes the connection between your eyes, brain, and body. The data collected during this assessment is compared to a vast database that matches age, gender, sport, and position for athletes, into a percentile of comparison. The higher the number for said percentile, the better performance observed during the assessment.',
      SUMMARY: 'Resumen',
      MY_SCORES: 'Mis Puntuaciones',
      TESTS_OVER_TIME: 'Pruebas A Lo Largo Del Tiempo',
      BREAKDOWN: 'Desglose',
      DATA: 'Datos',
      EXERCISE: 'Ejercicio',
      RESULT: 'Resultado',
      ERRORS: 'Errores',
      BEST: 'Mejor',
      BEST_PERCENT: 'Mejor (%)',
      TOTAL_SCORE: 'Puntuación Total',
      MEASUREMENTS: 'Mediciones',
      CURR_WEIGHT: 'Peso Actual',
      SCORE: 'Puntuación',
    },
    TRAININGS: {
      EXERCISE_PROGRAM: 'Programa de Ejercicios',
      COMPLETENESS: 'Integridad',
      NO_PROGRAMM_ASSIGNED: 'No hay un programa de ejercicios asignado a usted',
      HISTORY: 'Historia',
      HERE_IS_HISTORY: 'Aquí hay una historia de tu pasado',
      TAP_ONE_TO_VIEW: 'Toque uno para ver los resultados de ese día.',
      DATE: 'Fecha',
      NO_EXERCISE_FOR_STATION: 'No hay ejercicio para esta estación.',
      NO_PROGRAMM_ASSIGNED_YET:
        'Todavía no hay ningún programa de ejercicios asignado.',
      YOU_ARE_AT: 'Estás en',
      SAVE_YOUR_STATS: 'Guardar estadísticas',
      SAVING_YOUR_STATS: 'Guardando Tus Estadísticas',
      STATS_SAVED: 'Estadísticas guardadas',
      TRAINING_DETAILS: 'Detalles del tratamiento',
      TRAINING_AVAIL_24:
        'El tratamiento está disponible para ingresar datos justo las próximas 24 horas después de que finalice si no se confirmó.',
      CUSTOMIZED_PROGRAM_FOR: 'Programa personalizado para',
      EXERCISES: 'Ejercicios',
      VIDEO_NOT_FOUND: 'Video no disponible',
      ARE_YOU_IN_SEASON: '¿Estás actualmente en temporada?',
      THERE_IS_NO_EX_FOR_STATION: 'No hay ejercicio para esta estación.',
      YOUR_ARE_AT: 'Estás en',
      LAST_TRAINING_DAY_WARN:
        'Este es su último día de tratamiento. Tu programa de ejercicios se cerrará automáticamente.',
    },
    ABOUT: {
      CLIENT_VERSION: 'Client Version',
      API_VERSION: 'API Version',
    },
    PREFERENCES: {
      MEDICAL_COVERAGE: 'Cobertura médica',
      CARRIER: 'Compania de seguros',
      CARRIER_REQUIRED: 'Asegurador obligatorio',
      GROUP_NUMBER: 'Número de grupo',
      GROUP_NUMBER_REQUIRED: 'Se requiere el número de grupo',
      MEMBER_ID: 'Numero de mienbro',
      MEMBER_ID_REQUIRED: 'Se requiere numero de mienbro',
      INSURED_NAME: 'Nombre del asegurado',
      INSURED_NAME_REQUIRED: 'Se requiere nombre del asegurado',
      INSURED_DATE_OF_BIRTH: 'Fecha de nacimiento del asegurado',
      INSURED_DATE_OF_BIRTH_REQUIRED:
        'Se requiere la fecha de nacimiento del asegurado',
      INSURANCE_CARD: 'Tarjeta de seguro',
      ADD_INSURANCE_CARD: 'Añadir tarjeta de seguro',
      REMOVE_INSURANCE_CARD:
        '¿Estás segura de que quieres eliminar la tarjeta de seguro?',
    },
    RECOMMENDATIONS: {
      RECOMMENDATIONS: 'Recomendaciones',
      NO_RECOMMENDATIONS: 'Aún no hay recomendaciones',
      BOOK_NOW: 'Reserva ahora usando una de estas opciones:',
      GET_MEDICAL_TREATMENT1:
        'Para recibir tratamiento médico debes agregar tu',
      GET_MEDICAL_TREATMENT2: 'Información del seguro',
      GET_MEDICAL_TREATMENT3: '',
    },
    TESTING_HISTORY: {
      TESTING_HISTORY: 'Historial de pruebas',
      HERE_IS_YOUR_HISTORY:
        'Aquí tienes un historial de tus pruebas anteriores. Pulse sobre una para ver los resultados de esa prueba.',
    },
    EVENTS_INFO: {
      TOTAL_TESTS: 'Total de exámenes<br/>realizados',
      LAST_TEST_DATE: 'Fecha del<br/>último examen',
      SESSIONS: 'Sesiones',
      UNTIL_NEXT_TEST: 'hasta La Próxima prueba',
    },
  },
};
